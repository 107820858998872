<template>
  <div class="page-index">
    <Header
      background-image="images/hero.jpg"
      class="header--center header--center-bottom header--white--text"
    >
      <div class="container">
        <h2 class="card__title">
          {{ cardTitle }}
        </h2>

        <span class="card__text">
          {{ cardText }}
        </span>

        <TCard class="header__card">
          <div class="card__form">
            <PostalFormStep :progress-value="0" />
          </div>
        </TCard>

        <span class="card__subtext">
          {{ cardSubtext }}
        </span>
      </div>
    </Header>

    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HomeBlock from 'chimera/all/components/HomeBlock'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle:
        'Compara gratuitamente presupuestos para Paneles Solares > Prueba ahora',
      headDescription:
        'Introduce tu codigo postal y compara gratis hasta 6 empresas de tu provincia. El mejor precio para tu proveedor de paneles solares. Ahorra hasta un 40%. 100% Gratis',
      path: '/',
      usps: [
        {
          title: 'Rellena el formulario',
          text: 'Indícanos lo que necesitas y te pondremos en contacto con los mejores profesionales.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg')
        },
        {
          title: 'Obtenga cotizaciones gratis',
          text: 'Recibirás, como máximo, 6 ofertas de profesionales en tu zona. Así puedes ahorrar hasta un 40%',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg')
        },
        {
          title: 'Escoge al mejor profesional',
          text: 'Hemos seleccionado los mejores profesionales para ti, ahora sólo tienes que escoger',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg')
        }
      ],
      cardTitle: 'Encuentra un profesional en tu provincia',
      cardText: '¡Compara presupuestos antes de decidir y ahorra hasta un 40%!',
      cardSubtext: 'Compara hasta 6 instaladores en tu provincia'
    }
  }
}
</script>

<style lang="scss" scoped>
.page-index .header--white--text .header__card {
  @media (min-width: 541px) {
    @apply max-w-[444px];
  }
}
</style>
